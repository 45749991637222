import { useEffect, useState } from "react";
import "./forgotPassword.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { triggerOtp } from "../../Apis/sendOtp";
import { toast } from "react-toastify";
import { verifyOtp } from "../../Apis/verifyOtp";
import { resetPassword } from "../../Apis/resetPassword";
import { useNavigate } from "react-router-dom";
import { Header } from "../../components/header/header";

export const ForgotPassword = () => {
  const [showOtpPage, setShowOtpPage] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const [viewPassword, setViewPassword] = useState([false, false]);
  const [password, setPassword] = useState(["", ""]);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const navigate = useNavigate("");

  const handleSubmit = async (e, toastMsg = false) => {
    if (e !== "") e?.preventDefault();
    // console.log("email", email);
    try {
      const port = process.env.REACT_APP_AUTHENTICATION;
      const data = await triggerOtp(email, port);
      // console.log("handleSubmit otp trigger==>", data);
      if (data) {
        toast("Otp sent successfully!");
        setShowOtpPage(true);
      } else {
        throw new Error("Something went wrong");
      }
    } catch (err) {
      // console.log("handleSubmit otp trigger==>", err?.response?.data);
      let msg;
      if (err?.response?.data) msg = err?.response?.data;
      else msg = "Something went wrong! try again";
      toast(msg);
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    if (otp.length !== 6) {
      toast("Otp should be of 6 digits");
      return;
    }
    try {
      const port = process.env.REACT_APP_AUTHENTICATION;
      const data = await verifyOtp(email, otp, port);
      // console.log("handleOtpSubmit otp verify==>", data);
      if (data) {
        setShowOtpPage(false);
        setShowReset(true);
      }
    } catch (err) {
      // console.log("handleOtpSubmit otp verify==>", err?.response?.data);
      let msg;
      if (err?.response?.data) msg = err?.response?.data;
      else msg = "Something went wrong! try again";
      toast(msg);
    }
  };

  const checkPasswordCriteria = (value) => {
    const hasNumber = /\d/.test(value);
    const hasUppercase = /[A-Z]/.test(value);
    const hasSpecialChar = /[!@#$%^&*()]/.test(value);
    const hasLetter = /[a-zA-Z]/.test(value);
    const hasMinLength = value.length >= 8;

    return (
      hasNumber && hasUppercase && hasSpecialChar && hasLetter && hasMinLength
    );
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    if (!checkPasswordCriteria(password[0])) {
      toast("Password criteria is not satiesfied!");
      return;
    } else if (password[0] !== password[1]) {
      toast("password and confirm password do not match");
      return;
    }

    try {
      const port = process.env.REACT_APP_AUTHENTICATION;
      const data = await resetPassword(email, password[0], port);
      console.log("handlePasswordSubmit==>", data);
      if (data?.data && data?.headers?.authorization) {
        toast("password Updated Successfully");
        // navigate("/login");
        // console.log("handlePasswordSubmit===>", data?.data?.role);
        // console.log("handlePasswordSubmit===>", data?.headers?.authorization);
        if (data?.data?.role.toLowerCase() === "hr") {
          // navigate;
          sessionStorage.setItem("token", data?.headers?.authorization);
          sessionStorage.setItem("mail", email);
          sessionStorage.setItem("role", "HR");
          navigate("/calendarDetails");
        } else if (data?.data?.role.toLowerCase() === "expert") {
          sessionStorage.setItem("token", data?.headers?.authorization);
          sessionStorage.setItem("mail", email);
          sessionStorage.setItem("role", "Expert");
          navigate("/expertHome");
        } else {
          navigate("/login");
        }
      }
    } catch (err) {
      console.log("handlePasswordSubmit error==>", err);
      let msg;
      if (err?.response?.data?.exception) msg = err?.response?.data?.exception;
      else msg = "Something went wrong! try again";
      toast(msg);
    }
  };

  return (
    <div className="page-wrapper">
      <Header showDropdowns={false} />

      <div className="forgotPassword">
        <div className="forgot-wrapper">
          {!showOtpPage && !showReset && (
            <EmailSection
              email={email}
              setEmail={setEmail}
              handleSubmit={handleSubmit}
            />
          )}
          {/* otp page */}
          {showOtpPage && (
            <OtpSection
              email={email}
              otp={otp}
              setOtp={setOtp}
              handleOtpSubmit={handleOtpSubmit}
              // for resend purpose
              handleSubmit={handleSubmit}
            />
          )}
          {/* setpassword part */}
          {showReset && (
            <SetPassword
              handlePasswordSubmit={handlePasswordSubmit}
              viewPassword={viewPassword}
              setViewPassword={setViewPassword}
              password={password}
              setPassword={setPassword}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const EmailSection = ({ email, setEmail, handleSubmit }) => {
  return (
    <div>
      <div className="title">Forgot Password</div>
      <div className="desc">
        Provide us the Email ID of your WDP account and we will send you an
        Email/OTP with instructions to reset your password.
      </div>
      <form onSubmit={handleSubmit}>
        <div className="items">
          <label>Email</label>
          <input
            type="email"
            required
            pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
            placeholder="Enter your Email id"
            value={email}
            autoFocus
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="login-button">
          <button type="submit">Send Instructions</button>
        </div>
      </form>
    </div>
  );
};

const OtpSection = ({ email, handleOtpSubmit, otp, setOtp, handleSubmit }) => {
  const [timer, setTimer] = useState(30);
  const [isTimerRunning, setIsTimerRunning] = useState(true);

  const handleResend = async () => {
    await handleSubmit("", true);
    setIsTimerRunning(true);
  };

  useEffect(() => {
    let countdown;

    if (isTimerRunning) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    if (timer === 0) {
      setIsTimerRunning(false);
      setTimer(30);
      clearInterval(countdown);
    }

    return () => {
      clearInterval(countdown);
    };
  }, [isTimerRunning, timer]);
  return (
    <div>
      <div className="mail">
        <img src={"./mail-img.jpg"} alt="" />
      </div>
      <div className="title">Confirn Your Email</div>
      <div className="desc">
        Please take a moment to verify your email address. We sent an email with
        a OTP to <span> {email} </span>If you didn’t receive the email, check
        your spam folder.
      </div>
      <form onSubmit={handleOtpSubmit}>
        <div className="items">
          <label>OTP</label>
          <input
            type="number"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            maxLength={6}
            required
            autoFocus
            placeholder="Enter the 6 digit code"
          />
          <div className="resend">
            <span>
              <span
                onClick={handleResend}
                style={{ color: isTimerRunning ? "grey" : "blue" }}
              >
                Resend
                {isTimerRunning && <span> in {timer} seconds</span>}
              </span>
            </span>
          </div>
        </div>
        <div className="login-button">
          <button
            type="submit"
            style={{
              opacity: otp.length !== 6 ? ".7" : "1",
            }}
          >
            Continue
          </button>
        </div>
      </form>
    </div>
  );
};

const SetPassword = ({
  handlePasswordSubmit,
  viewPassword,
  setViewPassword,
  password,
  setPassword,
}) => {
  const handleSetPassword = (e, index) => {
    let temp = password;
    temp[index] = e.target.value;
    setPassword([...temp]);
  };

  const handleViewPassword = (index) => {
    let temp = viewPassword;
    temp[index] = !viewPassword[index];
    setViewPassword([...temp]);
  };

  return (
    <div>
      <div className="check">
        <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#0f9905" }} />
      </div>
      <div className="verified">Your email is successfully verified</div>
      <hr />
      <div className="title" style={{ marginBottom: "20px" }}>
        Set Password
      </div>

      <form onSubmit={handlePasswordSubmit}>
        <div className="input-wrappers">
          <label>Password</label>
          <div className="eye-input" style={{ marginBottom: "10px" }}>
            <input
              type={viewPassword[0] ? "text" : "password"}
              value={password[0]}
              onChange={(e) => handleSetPassword(e, 0)}
              placeholder="Enter your Password"
              autoFocus
              required
            />
            <FontAwesomeIcon
              onClick={() => handleViewPassword(0)}
              icon={viewPassword[0] ? faEye : faEyeSlash}
            />
          </div>
        </div>

        <div className="contains">
          <span> Contains at least : </span>
          <span style={{ color: /[0-9]/.test(password[0]) ? "green" : "red" }}>
            1 number,{" "}
          </span>
          <span style={{ color: /[A-Z]/.test(password[0]) ? "green" : "red" }}>
            1 uppercase character,{" "}
          </span>
          <span
            style={{
              color: /[!@#$%^&*()]/.test(password[0]) ? "green" : "red",
            }}
          >
            1 special character,{" "}
          </span>
          <span
            style={{ color: /[a-zA-Z]/.test(password[0]) ? "green" : "red" }}
          >
            1 letter,{" "}
          </span>
          <span style={{ color: password[0].length >= 8 ? "green" : "red" }}>
            Minimum of 8 characters
          </span>
        </div>

        <div className="input-wrappers">
          <label>Confirm Password</label>
          <div className="eye-input">
            <input
              type={viewPassword[1] ? "text" : "password"}
              value={password[1]}
              onChange={(e) => handleSetPassword(e, 1)}
              placeholder="Confirm your Password"
              required
            />
            <FontAwesomeIcon
              onClick={() => handleViewPassword(1)}
              icon={viewPassword[1] ? faEye : faEyeSlash}
            />
          </div>
        </div>

        <div className="login-button">
          <button type="submit">Confirm</button>
        </div>
      </form>
    </div>
  );
};
