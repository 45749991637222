import axios from "axios";
const PORT = process.env.REACT_APP_ROUND4;

export const viewProjectAssigned = async (data, port = PORT) => {
    const endPoint = `/api/v1/round4/hr/view/project`;
    const URL = process.env.REACT_APP_BASE_URL + port + endPoint;

    // let Config = {
    //     headers: {
    //         Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    //     },
    // };

    //   console.log("roleLogin ===>", data);
    // return await axios.post(URL, data, Config).then((res) => res?.data);

    const response = await axios.post(URL, data, {
        responseType: "blob", // Important for handling binary data like files
        // responseType: "arraybuffer",
    });

    const blob = new Blob([response.data], { type: "application/pdf" });
    // Create a URL for the blob
    const pdfBlobUrl = window.URL.createObjectURL(blob);
    // Open the PDF in a new tab
    window.open(pdfBlobUrl, "_blank");

};
