import axios from "axios";
const PORT = process.env.REACT_APP_ROUND3;

export const candidateSkillsForExpertRound = async (
  candidateId,
  port = PORT
) => {
  const endPoint = `/api/v1/round5/get/languages`;
  const URL = process.env.REACT_APP_BASE_URL + port + endPoint;
  let data = {
    // id: "mvananthu@gmail.com",
    id: candidateId,
  };
  let Config = {
    // headers: {
    //   Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    // },
  };
  return await axios.post(URL, data, Config).then((res) => res.data);
};
