import axios from "axios";
const PORT = process.env.REACT_APP_ROUND3;

export const currentMonthEvents = async (port = PORT) => {
  let Config = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      // Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOm51bGwsImlzcyI6Ii1jOGxOYV81UUVtdkt0RFY1cGxsX1EiLCJleHAiOjE2OTYwNzEwMDAsImlhdCI6MTY4NTY5NzYxMn0.zSYOC8S8yOA61s2RGwF2BmTDrCbeJ_a1D-djl_UTZbA`,
    },
  };

  const endPoint = `/meetings/completed/pending?page_size=200&page_number=1&type=scheduled`;
  const URL = process.env.REACT_APP_BASE_URL + port + endPoint;

  const data = currentMonthData();
  console.log("currentMonthEvents===>", data);
  return await axios.post(URL, data, Config).then((res) => res.data);
};

const currentMonthData = () => {
  // Get today's date
  var today = new Date();

  // Extract the month number from the date
  var month = String(today.getMonth() + 1).padStart(2, "0");

  // Format the date as "YYYY-MM-DD"
  console.log(
    "checkDateEmpty",
    today.getDate() < 10 ? `0${today.getDate()}` : today.getDate(),
    "c",
    today.getDate() < 10
  );
  const todayDate =
    today.getDate() < 10 ? `0${today.getDate()}` : today.getDate();

  var formattedDate = today.getFullYear() + "-" + month + "-" + todayDate;

  console.log("formattedDate==>", formattedDate);

  const data = {
    month: month,
    date: formattedDate,
    hrEmail: sessionStorage.getItem("mail"),
    // hrEmail: "dev.wissda@gmail.com",
  };
  return data;
};

export const getPendingProjects = async (port = PORT) => {
  let Config = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    // headers: {},
  };

  const endPoint = `/count/pending/project`;
  const URL = process.env.REACT_APP_BASE_URL + port + endPoint;

  var today = new Date();

  // Extract the month number from the date
  var month = String(today.getMonth() + 1).padStart(2, "0");

  const todayDate =
    today.getDate() < 10 ? `0${today.getDate()}` : today.getDate();

  // Format the date as "YYYY-MM-DD"

  var formattedDate = todayDate + "-" + month + "-" + today.getFullYear();

  const data = {
    date: formattedDate,
    // hrId: "dev.wissda@gmail.com",
    hrId: sessionStorage.getItem("mail"),
  };

  return await axios.post(URL, data, Config).then((res) => res.data);
};
