import { useState } from "react";
import "./passwordReset.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { verifyOldAndSetNewPassword } from "../../Apis/verifyOldAndSetNewPassword";
import { useNavigate } from "react-router-dom";
import { Header } from "../../components/header/header";
export const PasswordReset = () => {
  const [viewPassword, setViewPassword] = useState([false, false, false]);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const handleViewPassword = (index) => {
    let temp = viewPassword;
    temp[index] = !viewPassword[index];
    setViewPassword([...temp]);
  };

  const checkPasswordCriteria = (value) => {
    const hasNumber = /\d/.test(value);
    const hasUppercase = /[A-Z]/.test(value);
    const hasSpecialChar = /[!@#$%^&*()]/.test(value);
    const hasLetter = /[a-zA-Z]/.test(value);
    const hasMinLength = value.length >= 8;

    return (
      hasNumber && hasUppercase && hasSpecialChar && hasLetter && hasMinLength
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("clicked", oldPassword, newPassword, newPassword2);
    if (!checkPasswordCriteria(newPassword)) {
      toast("Password criteria is not satiesfied!");
      return;
    } else if (newPassword !== newPassword2) {
      toast("password and confirm password do not match");
      return;
    }

    try {
      const port = process.env.REACT_APP_AUTHENTICATION;
      const email = sessionStorage.getItem("mail");
      const data = await verifyOldAndSetNewPassword(
        email,
        oldPassword,
        newPassword,
        port
      );
      if (data) {
        // console.log("handleSubmit reset password ==> ", data);
        setSuccess(true);
      } else {
        throw new Error("Something went wrong");
      }
    } catch (err) {
      console.log("handleSubmit reset password error==>", err);
      let msg;
      if (err?.response?.data) msg = err?.response?.data;
      else msg = "Something went wrong! try again";
      toast(msg);
    }
  };

  const toDashboard = () => {
    let role = sessionStorage.getItem("role");
    if (role.toLocaleLowerCase() === "expert") {
      navigate("/expertHome");
    } else if (role.toLocaleLowerCase() === "hr") {
      navigate("/calendarDetails");
    } else {
      toast("please login again!");
      navigate("/login");
    }
  };

  return (
    <>
      <Header />

      <div className="reset-page">
        <div className="back-button" onClick={() => toDashboard()}>
          {"< Back to Dashboard"}
        </div>
        {!success && (
          <div className="main-content">
            <div className="title">Set New Password</div>
            <form onSubmit={handleSubmit}>
              <div className="input-wrappers">
                <label>Enter Old Password</label>
                <div className="eye-input">
                  <input
                    type={viewPassword[0] ? "text" : "password"}
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    placeholder="Enter your Password"
                    required
                  />
                  <FontAwesomeIcon
                    onClick={() => handleViewPassword(0)}
                    icon={viewPassword[0] ? faEye : faEyeSlash}
                  />
                </div>
              </div>
              <div className="line"></div>
              <div className="input-wrappers">
                <label>Enter New Password</label>
                <div className="eye-input">
                  <input
                    type={viewPassword[1] ? "text" : "password"}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="Enter your Password"
                    required
                  />
                  <FontAwesomeIcon
                    onClick={() => handleViewPassword(1)}
                    icon={viewPassword[1] ? faEye : faEyeSlash}
                  />
                </div>
              </div>
              <div className="contains">
                <span> Contains at least : </span>
                <span
                  style={{ color: /[0-9]/.test(newPassword) ? "green" : "red" }}
                >
                  1 number,{" "}
                </span>
                <span
                  style={{ color: /[A-Z]/.test(newPassword) ? "green" : "red" }}
                >
                  1 uppercase character,{" "}
                </span>
                <span
                  style={{
                    color: /[!@#$%^&*()]/.test(newPassword) ? "green" : "red",
                  }}
                >
                  1 special character,{" "}
                </span>
                <span
                  style={{
                    color: /[a-zA-Z]/.test(newPassword) ? "green" : "red",
                  }}
                >
                  1 letter,{" "}
                </span>
                <span
                  style={{ color: newPassword.length >= 8 ? "green" : "red" }}
                >
                  Minimum of 8 characters
                </span>
              </div>
              <div className="input-wrappers">
                <label>Confirm New Password </label>
                <div className="eye-input">
                  <input
                    type={viewPassword[2] ? "text" : "password"}
                    value={newPassword2}
                    onChange={(e) => setNewPassword2(e.target.value)}
                    placeholder="Confrim your Password"
                    required
                  />
                  <FontAwesomeIcon
                    onClick={() => handleViewPassword(2)}
                    icon={viewPassword[2] ? faEye : faEyeSlash}
                  />
                </div>
              </div>
              <div className="login-button">
                <button type="submit">Confirm</button>
              </div>
            </form>
          </div>
        )}
        {success && (
          <div className="main-content-2">
            <div className="check">
              <FontAwesomeIcon
                icon={faCircleCheck}
                style={{ color: "#0f9905" }}
              />
            </div>
            <div> Password Changed Successfully! </div>
            <div className="login-button">
              <button type="submit" onClick={() => toDashboard()}>
                Go back
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
