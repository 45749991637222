import axios from "axios";
const PORT = process.env.REACT_APP_ROUND3;
export const downloadProject = async (candidateId, endRoute, port = PORT) => {
  const endPoint = `/api/v1/round5/get/project`;
  const URL =
    process.env.REACT_APP_BASE_URL + port + endPoint + "/" + endRoute;
  //   console.log("downloadProject ===>", URL);
  const data = {
    // name: "mvananthu@gmail.com",
    name: candidateId,
  };

  const config = {
    url: URL,
    method: "POST",
    responseType: "blob", // Specify the response type as 'blob'
    data: data, // Include the request body data
    // headers: {},
    headers: {
      // Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  };

  return await axios(config).then((response) => {
    // Create a URL for the file blob
    const url = window.URL.createObjectURL(new Blob([response.data]));
    return url;
  });
};

export const downloadPDFQuestion = async (
  candidateId,
  endRoute,
  port = PORT
) => {
  const endPoint = `/api/v1/round5/get/project`;
  const URL =
    process.env.REACT_APP_BASE_URL + port + endPoint + "/" + endRoute;
  //   console.log("downloadProject ===>", URL);
  const data = {
    // name: "mvananthu@gmail.com",
    name: candidateId,
  };

  const config = {
    url: URL, // Replace with the URL to request the PDF
    method: "POST",
    responseType: "blob", // Important for handling the binary data of the PDF
    data: data,
    headers: {
      // Your headers here (e.g., Authorization)
    },
  };

  axios(config)
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "downloaded-file.pdf"); // or any other name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => {
      console.error("Error downloading the PDF file: ", error);
    });
};

export const downloadZipProject = async (
  candidateId,
  endRoute,
  port = PORT
) => {
  const endPoint = `/api/v1/round5/get/project`;
  const URL =
    process.env.REACT_APP_BASE_URL + port + endPoint + "/" + endRoute;
  //   console.log("downloadProject ===>", URL);
  const data = {
    // name: "mvananthu@gmail.com",
    name: candidateId,
  };

  const config = {
    url: URL, // Replace with the URL to request the ZIP
    method: "POST",
    // responseType: "arraybuffer", // Important for handling the binary data of the ZIP
    responseType: "blob", // Important for handling the binary data of the ZIP
    data: data,
    headers: {
      // Your headers here (e.g., Authorization)
    },
  };

  // axios(config)
  //   .then((response) => {
  //     const url = window.URL.createObjectURL(new Blob([response.data]));
  //     // const url = window.URL.createObjectURL(response.data);
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.setAttribute("download", "project-file.zip"); // or any other name
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //     window.URL.revokeObjectURL(url);
  //   })
  //   .catch((error) => {
  //     console.error("Error downloading the ZIP file: ", error);
  //   });
  axios(config)
    .then((response) => {
      console.log("Response received:", response); // Log the response for debugging
      if (response.data.size === 0) {
        console.error("Received an empty file");
        return;
      }

      // const url = window.URL.createObjectURL(new Blob([response.data]), {
      //   type: "application/zip",
      // });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "downloaded-file.zip");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => {
      console.error("Error during download:", error);
    });
};
