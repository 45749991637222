import { useNavigate } from "react-router-dom";
import styles from "./Home.module.css";
import { useEffect, useState } from "react";

const Home = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/login");
  }, []);
  return <div className={styles.buttonWrapper}>Home</div>;
};

export default Home;
