import { useEffect, useState } from "react";
import "./header.scss";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const Header = ({ showDropdowns = true }) => {
  const [showOptions, setShowOptions] = useState(false);
  const [role, setRole] = useState(() =>
    sessionStorage?.getItem("role")?.toLocaleLowerCase()
  );
  const navigate = useNavigate();

  const handleNavigate = (endUrl) => {
    navigate(`/${endUrl}`);
  };

  const handleLogoClick = () => {
    if (sessionStorage.getItem("role")) {
      let role = sessionStorage.getItem("role");
      if (role.toLowerCase() === "hr") {
        handleNavigate("calendarDetails");
      } else if (role.toLocaleLowerCase() === "expert") {
        handleNavigate("expertHome");
      } else {
        handleNavigate("login");
      }
    } else {
      // toast('You are not logged in')
      handleNavigate("login");
    }
  };

  const handleLogOut = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("mail");
    sessionStorage.removeItem("role");
    navigate("/login");
  };

  return (
    <div className="header-container">
      <div className="wrapper">
        <div onClick={() => handleLogoClick()}>
          <img src="./zero.logo.svg" alt="logo" />
        </div>
        {showDropdowns && (
          <div className="account" onMouseEnter={() => setShowOptions(true)}>
            <div>
              <img src={"./account_circle.svg"} alt="" />
            </div>
            <div>
              <img src={"./down-arrow-white.svg"} alt="" />
            </div>
            {showOptions && (
              <div
                className="dropDown"
                onMouseLeave={() => setShowOptions(false)}
              >
                {role === "hr" && (
                  <div onClick={() => handleNavigate("submissions")}>
                    Submission
                  </div>
                )}
                <div onClick={() => handleNavigate("password-reset")}>
                  <div>Update Password</div>
                  <div>Setup/Change your password</div>
                </div>
                <div onClick={() => handleLogOut()}>Logout</div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
