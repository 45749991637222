import { ExpertCalendar } from "../../components/ExpertData/ExpertCalendar/ExpertCalendar";
import { Header } from "../../components/header/header";
import "./expertHome.scss";

export const ExpertHome = () => {
  return (
    <div>
      <Header />

      <ExpertCalendar />
    </div>
  );
};
