import axios from "axios";
const PORT = process.env.REACT_APP_ROUND3;

export const verifyOldAndSetNewPassword = async (
  email,
  oldPassword,
  newPassword,
  port = PORT
) => {
  const endPoint = `/api/v1/user/forgotpassword/changing-password`;
  const URL = process.env.REACT_APP_BASE_URL + port + endPoint;

  let Config = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  };

  const data = {
    officialMail: email,
    oldPassword: oldPassword,
    newPassword: newPassword,
  };
  //   console.log("resetPassword ===>", data);
  return await axios.post(URL, data, Config).then((res) => res);
};
