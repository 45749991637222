import axios from "axios";
const PORT = process.env.REACT_APP_ROUND3;

export const verifyOtp = async (email, otp, port = PORT) => {
  const endPoint = `/api/v1/user/forgotpassword/verify-otp`;
  const URL = process.env.REACT_APP_BASE_URL + port + endPoint;

  let Config = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  };

  const data = {
    officialMail: email,
    otp: otp,
  };
  //   console.log("roleLogin ===>", data);
  return await axios.post(URL, data, Config).then((res) => res?.data);
};
