import axios from "axios";
const PORT = process.env.REACT_APP_ROUND3;
export const submitHrResult = async (data) => {
  let Config = {
    // headers: {
    //   Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    // },
    headers: {},
  };

  const endPoint = `/api/v1/round3/submit/hr/status`;
  const port = data?.port ? data?.port : PORT;
  const URL = process.env.REACT_APP_BASE_URL + port + endPoint;

  const name = data?.c_id;
  const isEligibleForRound4 = data?.isEligible === "No" ? false : true;

  const payload = {
    name: name,
    // name: "mvananthu@gmail.com",
    isEligibleForRound4: isEligibleForRound4,
  };

  if (data?.availableDate) {
    let date = formatDate(data?.availableDate);
    payload["availableDate"] = `${date} 15:30:45`;
  }

  if (data?.typeOfProject) {
    payload["typeOfProject"] = data?.typeOfProject;
  }

  console.log("submitHrResult ==>", payload);

  return await axios.post(URL, payload, Config).then((res) => res.data);
  // return false;
};

function formatDate(date) {
  const day = String(date.getDate()).padStart(2, "0"); // Get day and pad with leading zero if needed
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Get month (months are zero-based) and pad with leading zero if needed
  const year = String(date.getFullYear()); // Get full year

  return `${day}-${month}-${year}`;
}
