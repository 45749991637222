import axios from "axios";
const PORT = process.env.REACT_APP_ROUND3;
export const roleLogin = async (email, password, port = PORT) => {
  const endPoint = `/api/v1/user/hr/expert/login`;
  const URL = process.env.REACT_APP_BASE_URL + port + endPoint;

  let Config = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  };

  const data = {
    officialMail: email,
    password: password,
  };
  //   console.log("roleLogin ===>", data);
  return await axios.post(URL, data, Config).then((res) => res);
};
