import { useNavigate } from "react-router-dom";
import styles from "./r3Final.module.css";
import { Header } from "../../components/header/header";
import { useState } from "react";
import { useEffect } from "react";

const R3Final = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    if (sessionStorage?.getItem("role")?.toLowerCase() === "expert")
      navigate("/expertHome");
    else if (sessionStorage?.getItem("role")?.toLowerCase() === "hr")
      navigate("/calendarDetails");
    else navigate("/login");
  };

  return (
    <>
      <Header />

      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.circle}></div>
          <div className={styles.title}>Congratulations!</div>
          <div className={styles.desc}>
            Your test results have been successfully submitted.
          </div>
          <div className={styles.buttonWrapper}>
            {sessionStorage.getItem("role")?.toLowerCase() === "hr" && (
              <button onClick={() => navigate("/submissions")}>
                Go to Submissions
              </button>
            )}
            <button onClick={() => handleNavigate()}>Back to Homepage</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default R3Final;
