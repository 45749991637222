import axios from "axios";
const PORT = process.env.REACT_APP_ROUND3;

export const SubmitRound5Result = async (
  items,
  ratings,
  comments,
  inputForR4,
  totalRatings,
  r5Titles,
  ratingsR5,
  inputForR5,
  id,
  port = PORT
) => {
  const endPoint = `/api/v1/round5/save`;
  const URL = process.env.REACT_APP_BASE_URL + port + endPoint;

  console.log("SubmitRound5Result===>", URL);

  const data = await formatData(
    items,
    ratings,
    comments,
    inputForR4,
    totalRatings,
    r5Titles,
    ratingsR5,
    inputForR5,
    // (id = "mvananthu@gmail.com")
    id
  );
  let Config = {
    // headers: {
    //   Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    // },
  };
  return await axios.post(URL, data, Config).then((res) => res.data);
};

const formatData = async (
  items,
  ratings,
  comments,
  inputForR4,
  totalRatings,
  r5Titles,
  ratingsR5,
  inputForR5,
  id
) => {
  let combineRatingAndTopic = [];
  items.forEach((element, index) => {
    combineRatingAndTopic.push({
      section: element.title,
      rating: ratings[index],
      comments: inputForR4[index],
    });
  });

  let r5Combined = [];
  let r5AllScore = 0;
  r5Titles.forEach((element, index) => {
    r5Combined.push({
      language: element,
      rating: ratingsR5[index],
      comments: inputForR5[index],
    });
    r5AllScore += Number(ratingsR5[index]);
  });

  let r5TotalRating = r5AllScore / r5Combined.length;

  let data = {
    name: id,
    round4Assessment: {
      sections: combineRatingAndTopic,
      totalScore: Number(totalRatings),
      comments: comments,
    },
    round5Assessment: { languages: r5Combined, totalScore: r5TotalRating },
    // totalScore: Number(totalRatings),
  };

  console.log("all data ==>", data);

  return data;
};
