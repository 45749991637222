import { useEffect, useState } from "react";
import styles from "./hrMetting.module.css";
import ZoomMetting from "../../components/zoomScreen/zoomScreen";
import { useNavigate } from "react-router-dom";
import { getCandidateResume } from "../../Apis/candidateDetails";
import { submitCandidateResult } from "../../Apis/submitResult";
import Select from "react-select";
import { submitHrResult } from "../../Apis/submitHrResult";
import { submitRound } from "../../Apis/roundSubmission";
import { hrQuestions } from "../../mockData/hrQuestions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Header } from "../../components/header/header";
import { removeSessionData } from "../../commonFunctions/sessionData";
import { toast } from "react-toastify";
import { getAllProjectsForHr } from "../../Apis/getAllProjectsHr";
import { assignProjectToCandidate } from "../../Apis/assignProjectToCandidate";
import { viewProjectAssigned } from "../../Apis/viewProject";
// all constants------------------------------------------

const starDesc = [
  " Does not exhibit",
  "Does bare minimum",
  "Consistency in Performance",
  "Overcome Obstacles & Stretches",
  "** If score is below 2 in any of these competencies, the candidature will be rejected  ",
  "*** If total score is below 2.5 in any of these competencies, the candidature will be rejected",
];
const MAX_RATING = 5;

// all constants-------------------------------------------

const HrMetting = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [ratings, setRatings] = useState(Array(hrQuestions.length).fill(0));
  const [totalRatings, setTotalRatings] = useState(0);
  const [comments, setComments] = useState("");
  const navigate = useNavigate();

  const handleItemClick = (index) => {
    setSelectedItem(index === selectedItem ? null : index);
  };

  const handleTotalRatings = (data) => {
    // console.log("ratings", data);
    let total = 0;
    let count = 0;
    data.forEach((element) => {
      // if (element) {
      total += element;
      count++;
      // }
    });
    let resRating = total / count;
    resRating = resRating.toFixed(2);
    setTotalRatings(resRating);
  };

  const handleRatingChange = (index, rating) => {
    const newRatings = [...ratings];
    newRatings[index] = rating;
    handleTotalRatings(newRatings);
    setRatings(newRatings);
  };

  const handleSubmit = async () => {
    try {
      console.log("submitted", ratings);
      const id = sessionStorage.getItem("candidateId");
      const res = await submitCandidateResult(
        hrQuestions,
        ratings,
        comments,
        id
      );
      console.log("handleSubmit r3 final ====>", res);
      let project = sessionStorage.getItem("project");
      if (project && project !== "Add later") {
        const res2 = await submitRound(
          "round3",
          id,
          process.env.REACT_APP_DEVELOPER
        );
      }
      removeSessionData(
        "r2Rating",
        "r1Rating",
        "meet_date",
        "candidateId",
        "zoomUrl",
        "candidateName"
      );
      navigate("/roundFinal");
    } catch (err) {
      console.log("handleSubmit ===>", err);
    }
  };

  const Ratings = () => {
    return (
      <>
        <div className={styles.itemList}>
          {hrQuestions.map((item, index) => (
            <div key={index} className={styles.eachItem}>
              <div
                className={`${styles.itemTitle} ${
                  selectedItem === index ? styles.selected : ""
                }`}
                onClick={() => handleItemClick(index)}
              >
                <span className={styles.arrow}>
                  <img
                    src={"./down-arrow.svg"}
                    alt=""
                    style={{
                      transform: `${
                        selectedItem === index ? "rotate(180deg)" : ""
                      }`,
                    }}
                  />
                </span>
                <span>{item.title}</span>
                <span className={styles.itemRating}>
                  {Array(MAX_RATING)
                    .fill()
                    .map((_, i) => (
                      <span
                        key={i}
                        className={`${styles.star} ${
                          i < ratings[index] ? styles.filled : ""
                        }`}
                        onClick={() => handleRatingChange(index, i + 1)}
                      >
                        ★
                      </span>
                    ))}
                </span>
              </div>
              {selectedItem === index && (
                <div>
                  {item.description.map((ele, i) => (
                    <div key={i}>
                      <div>
                        {i + 1}. {ele.desc}
                      </div>
                      {ele?.subdesc?.length > 0 && (
                        <div>
                          {ele.subdesc.map((content, i) => (
                            <div key={i}>
                              {String.fromCharCode(97 + i)} {")"} {content}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
          <div className={styles.totalScore}>
            <div className={styles.totalValue}>
              <div>Total Score</div>
              <div>(average of all competencies score)</div>
            </div>
            <div>{totalRatings} ★</div>
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    document.body.style.overflow = "scroll";
  }, []);

  return (
    <>
      <Header />

      <div className={styles.container}>
        <ZoomScreen
          comments={comments}
          setComments={setComments}
          handleSubmit={handleSubmit}
          ratings={ratings}
        />
        {/* ratings part */}

        <Ratings />
      </div>
    </>
  );
};

const ZoomScreen = ({ comments, setComments, handleSubmit, ratings }) => {
  const [viewDetails, setViewDetails] = useState(false);
  const [clientName, setClientName] = useState("LOREM IPSUM");
  const [zoomUrl, setZoomUrl] = useState(sessionStorage.getItem("zoomUrl"));
  const [candidateName, setCandidateName] = useState(
    sessionStorage.getItem("candidateName")
  );
  const [meetDate, setMeetDate] = useState("");
  const [viewPopUp, setViewPopUp] = useState(false);
  const [viewFinalPage, setViewFinalPage] = useState(false);
  const [r1r, setR1r] = useState(sessionStorage.getItem("r1Rating"));
  const [r2r, setR2r] = useState(sessionStorage.getItem("r2Rating"));
  const navigate = useNavigate();

  const handleFormatDate = () => {
    const date = new Date(sessionStorage.getItem("meet_date"));
    const formattedDate = date.toLocaleDateString("en-GB"); // Use "en-GB" for dd/mm/yyyy format
    setMeetDate(formattedDate);
  };

  const handleViewResume = async () => {
    try {
      const res = await getCandidateResume(
        sessionStorage.getItem("candidateId")
      );
      const downloadLink = document.createElement("a");
      downloadLink.href = res;
      // Set the file name
      downloadLink.setAttribute("download", "resume.pdf");
      // Trigger the download by programmatically clicking the link
      downloadLink.click();
      console.log("handleDownload==>", res);
    } catch (err) {
      console.log("handleDownload error ==>", err);
    }
  };

  const handleResultSubmit = () => {
    handleSubmit();
  };

  const handleResultSubmitClick = () => {
    // ratings
    for (let i = 0; i < ratings.length; i++) {
      if (ratings[i] === 0) {
        toast("Please select all ratings");
        return;
      }
    }
    setViewPopUp(true);
  };

  useEffect(() => {
    handleFormatDate();
  }, []);

  return (
    <div>
      <div className={styles.zoomScreen}>
        <ZoomMetting zoomUrl={zoomUrl} />
      </div>
      <div className={styles.details}>
        <div className={styles.meetDetails}>
          <div>{candidateName}</div>
          <div>CANDIDATE NAME</div>
        </div>
        <div className={styles.meetDetails}>
          <div>{meetDate}</div>
          <div>DATE OF INTERVIEW</div>
        </div>
        {/* <div className={styles.meetDetails}>
          <div>{clientName}</div>
          <div>CLIENT NAME</div>
        </div> */}
        <div className={styles.meetDetails}>
          <div>{r1r}</div>
          <div>R1 Rating</div>
        </div>
        <div className={styles.meetDetails}>
          <div>{r2r}</div>
          <div>R2 Rating</div>
        </div>
      </div>
      <div>
        <button className={styles.resume} onClick={() => handleViewResume()}>
          Download Resume
        </button>
      </div>
      <div>
        <div className={styles.comment}>
          <textarea
            type="text"
            placeholder="Overall comment ..."
            value={comments}
            onChange={(e) => setComments(e.target.value)}
          />
        </div>
        <div className={styles.knowMore}>
          <div onClick={() => setViewDetails(!viewDetails)}>
            <div>
              <div>
                Know more about <span>★</span>
              </div>
            </div>
            <div>
              <img
                style={{
                  transform: `${viewDetails ? "rotate(180deg)" : ""}`,
                }}
                src={"./down-arrow.svg"}
                alt="view"
              />
            </div>
          </div>
          <div className={styles.startDetails}>
            {viewDetails &&
              starDesc.map((item, index) => (
                <div key={index} className={styles.eachStar}>
                  <span>{index < 4 && index + 1}</span>
                  <span>{index < 4 && "★"}</span>
                  <span>
                    {" "}
                    {index < 4 && "-"} {item}
                  </span>
                </div>
              ))}
          </div>
        </div>
        <div className={styles.buttonsWrapper}>
          <button onClick={() => navigate("/calendarDetails")}>Go Back</button>
          <button onClick={() => handleResultSubmitClick()}>
            Submit Result
          </button>
        </div>
      </div>

      {viewPopUp ? (
        viewFinalPage ? (
          <SubmitPopUp
            setViewPopUp={setViewPopUp}
            handleResultSubmit={handleResultSubmit}
            setViewFinalPage={setViewFinalPage}
          />
        ) : (
          <NextSteps
            setViewPopUp={setViewPopUp}
            setViewFinalPage={setViewFinalPage}
            handleSubmit={handleResultSubmit}
          />
        )
      ) : (
        ""
      )}
    </div>
  );
};

const NextSteps = ({ setViewPopUp, setViewFinalPage, handleSubmit }) => {
  // const [options, setOptions] = useState([
  //   { value: "2hrs", label: "2hrs Project" },
  //   { value: "48hrs", label: "48hrs Project" },
  //   { value: "Add later", label: "Add later" },
  // ]);
  const [options, setOptions] = useState([]);
  const [allProjectsList, setAllProjectsList] = useState({});
  const [isEligible, setIsEligible] = useState("");
  const [isDisabled, setIsdisabled] = useState(true);
  const [project, setProject] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const handleOptionChange = (val) => {
    // setProject(val.value);
    setProject(val);
    setIsdisabled(false);
  };

  const handleEligibility = (event) => {
    if (event.target.value === "No") {
      setIsdisabled(false);
      setProject("");
    } else if (event.target.value === "Yes" && project) {
      setIsdisabled(false);
    } else {
      setIsdisabled(true);
    }
    setIsEligible(event.target.value);
    console.log("project==>", project);
  };

  const handleResultSubmit = async () => {
    try {
      // adding to retrieve during submission
      sessionStorage.setItem("project", project);
      const c_id = sessionStorage.getItem("candidateId");
      const port = process.env.REACT_APP_ROUND3;
      const data = {};
      data["isEligible"] = isEligible;
      data["c_id"] = c_id;
      data["port"] = port;
      if (isEligible) {
        // data["typeOfProject"] = project;
        data["typeOfProject"] = "24hrs";
        data["availableDate"] = selectedDate;
      }

      const res = await submitHrResult(data);
      if (res && isEligible !== "No") {
        const port = process.env.REACT_APP_ROUND4;
        const data = {
          name: sessionStorage.getItem("candidateId"),
          projectName: project?.value,
          projectUrl: project?.projectUrl,
        };
        const assignProject = await assignProjectToCandidate(data, port);
        console.log("handleResultSubmit===>", assignProject?.data);
        // setViewFinalPage(true);
        handleSubmit();
      } else if (res) {
        setViewFinalPage(true);
        handleSubmit();
      }
    } catch (err) {
      console.log("handleResultSubmit ==> ", err);
    }
  };

  const handleGetAvailableProjects = async () => {
    // console.log("handleGetAvailableProjects");
    try {
      const PORT = process.env.REACT_APP_ROUND4;
      const data = {
        // c_id: sessionStorage.getItem("candidateId"),
        field: sessionStorage.getItem("field"),
        role: sessionStorage.getItem("subfield"),
      };
      const allProjects = await getAllProjectsForHr(data, PORT);
      // console.log("handleGetAvailableProjects ==> ", allProjects?.data);

      if (allProjects) {
        setAllProjectsList({ ...allProjects?.data });
        const optionsData = allProjects?.data?.projects?.map((project) => ({
          value: project?.projectName,
          label: project?.projectName,
          projectUrl: project.projectUrl,
        }));
        // console.log("options data==>", optionsData);
        setOptions([...optionsData]);
      }
    } catch (err) {
      console.log("error in handleGetAvailableProjects", err);
    }
  };

  const handleShowProject = async (ele) => {
    // console.log("data==>", ele);
    if (!ele?.value) return;
    try {
      const data = {
        name: sessionStorage.getItem("candidateId"),
        projectName: ele?.value,
      };
      const PORT = process.env.REACT_APP_ROUND4;
      const res = await viewProjectAssigned(data, PORT);
      // console.log("handleShowProject==>", res);
    } catch (err) {
      console.log("handleShowProject error==>", err);
      toast("Error viewing project");
    }
  };

  useEffect(() => {
    setSelectedDate(() => new Date());
    handleGetAvailableProjects();
  }, []);

  return (
    <div className={styles.mainPopUp}>
      <div className={styles.first}>
        {/* img cross */}
        <div className={styles.second}>
          <img
            onClick={() => {
              setViewPopUp(false);
              setViewFinalPage(false);
            }}
            src={"./Close.svg"}
            alt=""
          />
        </div>
        <div className={styles.third}>
          Is the candidate eligible for next round?
        </div>
        <div className={styles.eligibility}>
          <div>
            <label>
              <input
                type="radio"
                value="Yes"
                checked={isEligible === "Yes"}
                onChange={handleEligibility}
              />
              Yes
            </label>
          </div>
          <div>
            <label>
              <input
                type="radio"
                value="No"
                checked={isEligible === "No"}
                onChange={handleEligibility}
              />
              No
            </label>
          </div>
        </div>
        {isEligible === "Yes" && (
          <>
            <AvailableDates
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
            />
            <div className={styles.dropDown}>
              <div className={styles.projectTitle}>
                Project assigned for next Round
              </div>
              <Select
                className={styles.selectStyle}
                options={options}
                onChange={handleOptionChange}
              />
              {project?.value && (
                <button
                  className={styles.projectName}
                  onClick={() => handleShowProject(project)}
                >
                  View Project
                </button>
              )}
            </div>
            <div className={styles.fourth}>
              Add later: You need to add it under Profile/Submissions within
              24hrs without fail
            </div>{" "}
          </>
        )}
        <div className={styles.fifth}>
          {/* <button onClick={() => setViewFinalPage(true)}>Skip for now</button> */}
          <button
            style={{
              opacity: `${isDisabled ? "0.7" : "1"}`,
              cursor: `${isDisabled ? "not-allowed" : "pointer"}`,
            }}
            onClick={() => handleResultSubmit()}
            disabled={isDisabled}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

const SubmitPopUp = ({
  setViewPopUp,
  handleResultSubmit,
  setViewFinalPage,
}) => {
  return (
    <div className={styles.mainPopUp}>
      <div className={styles.first}>
        {/* img cross */}
        <div className={styles.second}>
          <img
            onClick={() => {
              setViewPopUp(false);
              setViewFinalPage(false);
            }}
            src={"./Close.svg"}
            alt=""
          />
        </div>
        <div className={styles.third}>
          Are you sure you want to submit the results?
        </div>
        <div className={styles.fourth}>
          Once submitted, you will not be able to modify any ratings or
          comments.
        </div>
        <div className={styles.fifthButton}>
          <button
            onClick={() => {
              setViewPopUp(false);
              setViewFinalPage(false);
            }}
          >
            Cancel
          </button>
          <button onClick={() => handleResultSubmit()}>Submit Results</button>
        </div>
      </div>
    </div>
  );
};

const AvailableDates = ({ selectedDate, setSelectedDate }) => {
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const isWeekend = (date) => {
    const day = date.getDay();
    if (day === 0 || day === 6) return false;
    return true;
  };

  return (
    <div className={styles.datepicker}>
      <div>Candidate availibility</div>
      <div>
        <DatePicker
          selected={selectedDate}
          onChange={handleDateChange}
          filterDate={isWeekend}
          minDate={new Date()}
        />
        <div>
          <img src={"./calendar-red.svg"} alt="" />
        </div>
      </div>
    </div>
  );
};

export default HrMetting;
