import { useState } from "react";
import "./login.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { roleLogin } from "../../Apis/login";
import { toast } from "react-toastify";
import { Header } from "../../components/header/header";
export const LoginPage = () => {
  const [password, setPassword] = useState(true);
  const [cookies, setCookie] = useCookies(["user"]);
  const [email, setEmail] = useState(cookies.username ? cookies.username : "");
  const [pass, setPass] = useState(cookies.Password ? cookies.Password : "");
  const [remember, setRemember] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("clicked", email, pass, remember);
    try {
      const port = process.env.REACT_APP_AUTHENTICATION;
      const data = await roleLogin(email, pass, port);
      // console.log("handleSubmit===> login page", data);
      if (data?.headers?.authorization) {
        if (remember) rememberCookie();
        sessionStorage.setItem("token", data?.headers?.authorization);
        sessionStorage.setItem("mail", email);
        sessionStorage.setItem("role", data?.data?.role);
        if (data?.data?.role === "Expert") {
          navigate("/expertHome");
        } else if (data?.data?.role.toLowerCase() === "hr") {
          navigate("/calendarDetails");
        } else {
          // console.log("handleSubmit else===> login page ==>auth token not found");
          throw new Error("Role not found");
        }
      } else {
        // console.log("handleSubmit else===> login page ==>auth token not found");
        throw new Error("Something went wrong");
      }
    } catch (err) {
      // console.log("handleSubmit error===> login page", err);
      let msg;
      if (err?.response?.data?.exception) msg = err?.response?.data?.exception;
      else msg = "Something went wrong! try again";
      toast(msg);
    }
  };

  const rememberCookie = () => {
    setCookie("username", email, {
      path: "http://localhost:3000",
    });
    setCookie("Password", pass, {
      path: "http://localhost:3000",
    });
  };
  return (
    <div className="page-wrapper">
      <Header showDropdowns={false} />
      <div className="login-page">
        <div className="login-wrapper">
          <div className="login">Log In</div>
          <form onSubmit={handleSubmit}>
            {/* email  */}
            <div className="input-wrapper item1">
              <label>Official Email ID</label>
              <input
                // title=""
                type="email"
                value={email}
                pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your Official Email address"
                required
              />
            </div>
            {/* password */}
            <div className="input-wrapper">
              <label>Password</label>
              <div className="input-item2">
                <input
                  type={password ? "password" : "text"}
                  placeholder="Enter your Password"
                  value={pass}
                  onChange={(e) => setPass(e.target.value)}
                  required
                />
                <span>
                  <FontAwesomeIcon
                    onClick={() => setPassword(!password)}
                    icon={password ? faEyeSlash : faEye}
                  />
                </span>
              </div>
            </div>
            {/* remember and forgot option */}
            <div className="remember">
              <span className="remember-text">
                <input
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  onChange={(event) => {
                    setRemember(event.target.checked);
                  }}
                />
                <label>Remember me?</label>
              </span>
              <span
                className="forgot"
                onClick={() => navigate("/forgot-password")}
              >
                Forgot Password?
              </span>
            </div>
            <div className="login-button">
              <button type="submit">Log In</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
