import axios from "axios";

const PORT = process.env.REACT_APP_ROUND3;

export const submitRound = async (roundName, candidateId, port = PORT) => {
  let Config = {
    // headers: {
    //   Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    // },
    header: {},
  };
  //   https://qa.wissda.cloud:process.env.REACT_APP_DEVELOPER/api/v1/assessment/round2/status
  const endPoint = `/api/v1/assessment/${roundName}/status`;
  const URL = process.env.REACT_APP_BASE_URL + port + endPoint;

  const data = {
    // id: "dev.wissda@gmail.com",
    id: candidateId,
    status: true,
  };
  return await axios.post(URL, data, Config).then((res) => res.data);
};
