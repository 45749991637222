import axios from "axios";
const PORT = process.env.REACT_APP_ROUND4;

export const getAllProjectsForHr = async (data, port = PORT) => {
    const endPoint = `/api/v1/round4/list/projects`;

    const URL = process.env.REACT_APP_BASE_URL + port + endPoint;

    let Config = {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
    };

    return await axios.post(URL, data, Config).then((res) => res);
};
